//burger
const burgerBtn = document.querySelector(".header__burger");
const burgerMenu = document.querySelector(".header__nav");
const body = document.querySelector("body");

const burgerMenuToggle = function (event) {
  event.preventDefault();
  burgerBtn.classList.toggle("active");
  burgerMenu.classList.toggle("active");
  body.classList.toggle("noscroll");
};
//burger

//comfort slider
$(".comfort__content-slider").slick({
  arrows: false,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
});
//comfort slider

//comfort tabs
const comfortTabsInner = document.querySelectorAll(".comfort__items");
const comfortTab = document.querySelectorAll(".comfort__items-item");
const comfortContent = document.querySelectorAll(".comfort__contents-content");

function change(arr, i) {
  arr.forEach((item) => {
    item.forEach((i) => {
      i.classList.remove("active");
    });
    item[i].classList.add("active");
  });
}
for (let i = 0; i < comfortTab.length; i++) {
  comfortTab[i].addEventListener("click", () => {
    change([comfortTab, comfortContent], i);
  });
}
//comfort tabs

//catalog filters
const catalogFilter = document.querySelectorAll(".catalog__line-text");
for (let i = 0; i < catalogFilter.length; i++) {
  catalogFilter[i].addEventListener("click", () => {
    catalogFilter[i].classList.toggle("active");
  });
}
//catalog filters
